<template>
<div class ="column">
<div>
    <h1 id="ImgCentered" style="width:fit-content;color : rgb(0,0,255);font-size:1.6em">Expériences récentes pour la cité des sciences</h1>
</div>
    <div class="row">
    <div style="margin-right:30px">
        <h1 style="color : rgb(0,0,255);font-size:1.6em">Théatre des ustensiles - Expo Banquet</h1>
        <p style="width:500px; text-align:justify">
            Projet visant à reproduire la sensation de voir
            quelqu'un cuisiner. Par le bruit des ustensiles de cuisine avec le mouvement. Par 
            quatres phases que sont les préparations matinales, les coupes, la cuisson, et le dressage final.
        </p>
        <img src="../assets/theatre.jpg" />
    </div>
    <div>
         <h1 style="color : rgb(0,0,255);font-size:1.6em">Sablier - Exposition Foules</h1>
        <p style="width:500px; text-align:justify">
            Projet visant à simuler la progression d'une foule au cours d'une évacuation.
            En effet lors d'une évacuation d'urgence la foule doit vite sortir mais une foule est
            désordonnée par définition. Nous devons donc la guider vers le bon chemin.
            C'est ce que représente le Sablier avec l'obstacle étant le guide de la foule.
        </p>
        <img src="../assets/Photo_Sablier.jpg" />
    </div>
    </div>
    <div class="row">
    <div style="margin-right:30px">
        <h1 style="color : rgb(0,0,255);font-size:1.6em">Frigo - Expo Froid</h1>
        <p style="width:500px; text-align:justify">
            Projet visant à reproduire les principes de base du frigo.
            C'est-à-dire que nous montrons comment un frigo fonctionne avec tous ses mécanismes. 
            Il était présent dans l'exposition temporaire froid.
        </p>
        <img src="../assets/le_frigo.jpg" />
    </div>
    <div>
         <h1 style="color : rgb(0,0,255);font-size:1.6em">Présentoir des capteurs - expo ROBOTS</h1>
        <p style="width:500px; text-align:justify">
            Voici tous les divers capteurs utilisés avec les robots, nous pouvons voir les robots comme
            quelque chose d'incroyable mais pour que ces robots puissent réagir à une situation donnée.
            Il leur faut des capteurs ! En effet n'ayant pas de sens comme nous les robots ont besoin de quelque chose
            pour remplir cette fonction et obtenir des informations. Les différents capteurs sont parfaits pour cela.
            Il est présent dans l'exposition permanente des robots.
        </p>
        <img src="../assets/Presentoir_Les_capteurs.jpg" />
    </div>
    </div>
</div>
</template>

<script>
export default {
    name:"theater"
}
</script>

<style>

</style>