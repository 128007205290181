<template>
<div>
    <div _ngcontent-c13="" class="boiler" id="boiler">
    <div _ngcontent-c13=""></div><ng-component _nghost-c23=""><h3 _ngcontent-c23="" placeholder="Document name" style="color: rgb(51, 51, 51); font-style: normal; text-align: center;" type="name">&nbsp;</h3>

<h3 _ngcontent-c23="" placeholder="Document name" style="color: rgb(51, 51, 51); font-style: normal; text-align: center;" type="name"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-size:18px;">Mentions légales et politique de confidentialité</span></span></h3>

<p _ngcontent-c23="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><!----><!----><span _ngcontent-c23="" rl-text="content1">La société&nbsp;<span _ngcontent-c23="" class="boiler-field">DUCRE ENERGIE</span></span><!---->,&nbsp;soucieuse&nbsp;des droits des individus, notamment au regard des traitements automatisés&nbsp;et dans une volonté de transparence avec ses clients, a mis en place une politique reprenant l’ensemble de ces traitements, des finalités poursuivies par ces derniers ainsi que des moyens d’actions à la disposition des individus afin qu’ils puissent au mieux exercer leurs droits.&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Pour toute information complémentaire sur la protection des données personnelles, nous vous invitons à consulter le site&nbsp;:&nbsp;</span><a _ngcontent-c23="" href="https://www.cnil.fr/"><span _ngcontent-c23="" style="color:#000000;">https://www.cnil.fr/</span></a></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal; text-align: start;">La poursuite de la navigation sur ce site vaut acceptation sans réserve des dispositions et conditions d'utilisation qui suivent.</span> </span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">La version actuellement en ligne de ces conditions d'utilisation est la seule opposable pendant toute la durée d'utilisation du site et jusqu'à ce qu'une nouvelle version la remplace.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 1 - Mentions&nbsp;légales</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><u _ngcontent-c23="">1.1&nbsp;Site (ci-après «&nbsp;le site&nbsp;»)</u>&nbsp;: </span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" class="boiler-field">cepram.com</span></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><u _ngcontent-c23="">1.2&nbsp;Éditeur (ci-après «&nbsp;l'éditeur&nbsp;»)</u>&nbsp;:&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<!---->

<!----><div _ngcontent-c23="" rl-text="content3">
<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" class="boiler-field">DUCRE ENERGIE</span> <span _ngcontent-c23="" class="boiler-field">SARL</span>&nbsp;au capital de&nbsp;<span _ngcontent-c23="" class="boiler-field">8 000</span>&nbsp;€</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">dont le siège social est situé :&nbsp;<span _ngcontent-c23="" class="boiler-field">27 rue Gabriel Péri</span>&nbsp;<span _ngcontent-c23="" class="boiler-field">94700 Maisons-Alfort</span>&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">représentée par&nbsp;<span _ngcontent-c23="" class="boiler-field">Bruno Benard</span>, en sa qualité de&nbsp;<span _ngcontent-c23="" class="boiler-field">Gérant</span></span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">immatriculée au RCS de <span _ngcontent-c23="" class="boiler-field">Maisons-Alfort 337628069</span></span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">n° de téléphone&nbsp;: <span _ngcontent-c23="" class="boiler-field">0143760624</span></span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">adresse mail : <span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span></span></span></span></p>

<!---->
</div>

<!---->

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><u _ngcontent-c23="">1.3&nbsp;Hébergeur (ci-après « l'hébergeur »)</u> :&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" class="boiler-field">cepram.com</span>&nbsp;est hébergé par&nbsp;<span _ngcontent-c23="" class="boiler-field">OVH</span>, dont le siège social est situé <span _ngcontent-c23="" class="boiler-field">2 rue Kellermann</span>&nbsp;<span _ngcontent-c23="" class="boiler-field">59100 Roubaix - France</span>.</span></span></span><br _ngcontent-c23="">
&nbsp;</p>

<!----><div _ngcontent-c23="" rl-text="content1">
<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><u _ngcontent-c23="">1.4 Délégué à la protection des données (DPO) :</u></span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Un délégué à la protection des données :&nbsp;<span _ngcontent-c23="" class="boiler-field">Bruno Benard</span>,&nbsp;<span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>, est à votre disposition pour toute&nbsp;question relative à la protection de vos données personnelles.</span></span></span></p>
</div>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 2 -&nbsp;Accès au site</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">L'accès au site et son utilisation sont réservés à un usage strictement personnel. Vous vous engagez à ne pas utiliser ce site et les informations ou données qui y figurent à des fins commerciales, politiques, publicitaires et pour toute forme de sollicitation commerciale et notamment l'envoi de courriers électroniques non sollicités.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 3 -&nbsp;Contenu du site</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner ce site et plus généralement tous les éléments reproduits ou utilisés sur le site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Ils sont la propriété pleine et entière de l'éditeur ou de ses partenaires. Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'éditeur, sont strictement interdites. Le fait pour l'éditeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 4 -&nbsp;Gestion du site</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Pour la bonne gestion du site, l'éditeur pourra à tout moment&nbsp;:</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;&nbsp;suspendre, interrompre ou limiter l'accès à tout ou partie du site, réserver l'accès au site, ou à certaines parties du site, à une catégorie déterminée d'internautes ;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;&nbsp;supprimer toute information pouvant en perturber le fonctionnement ou entrant en contravention avec les lois nationales ou internationales ;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;&nbsp;suspendre le site afin de procéder à des mises à jour.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 5 -&nbsp;Responsabilités</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">La responsabilité de l'éditeur ne peut être engagée en cas de défaillance, panne, difficulté ou interruption de fonctionnement, empêchant l'accès au site ou à une de ses fonctionnalités.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Le matériel de connexion au site que vous utilisez est sous votre entière responsabilité. Vous devez prendre toutes les mesures appropriées pour protéger votre matériel et vos propres données notamment d'attaques virales par Internet. Vous êtes par ailleurs&nbsp;seul responsable des sites et données que vous consultez.</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">L'éditeur ne pourra être tenu responsable en cas de poursuites judiciaires à votre encontre :</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;&nbsp;du fait de l'usage du site ou de tout service accessible&nbsp;via&nbsp;Internet&nbsp;;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;&nbsp;du fait du non-respect par vous des présentes conditions générales.</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">L'éditeur n'est pas responsable des dommages causés à vous-même, à des tiers et/ou à votre équipement du fait de votre connexion ou de votre utilisation du site et vous renoncez à toute action contre lui de ce fait.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Si l'éditeur venait à faire l'objet d'une procédure amiable ou judiciaire en&nbsp;raison de votre utilisation du site, il pourra se retourner contre vous pour obtenir l'indemnisation de tous les préjudices, sommes, condamnations et frais qui pourraient découler de cette procédure.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 6 -&nbsp;Liens hypertextes</strong></span></span></span><br _ngcontent-c23="">
&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">La mise en place par les utilisateurs&nbsp;de tous liens hypertextes vers tout ou partie du site&nbsp;<!----><span _ngcontent-c23="" rl-text="content27">est autorisée par l'éditeur. Tout lien devra être retiré sur simple demande de l'éditeur.</span><!---->&nbsp;</span></span></span></p>

<!---->

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Toute information accessible&nbsp;via&nbsp;un lien vers d'autres sites n'est pas publiée par l'éditeur. L'éditeur ne dispose d'aucun droit sur le contenu présent dans ledit lien.&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 7 -&nbsp;Collecte et protection&nbsp;des données</strong></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Vos données sont collectées par <!----><!----><span _ngcontent-c23="" rl-text="content5">la société&nbsp;<span _ngcontent-c23="" class="boiler-field">DUCRE ENERGIE</span></span></span></span></span><span _ngcontent-c23="" style=""><span _ngcontent-c23="" style=""><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23=""><!---->.</span></span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Une donnée à caractère personnel désigne toute information concernant une personne physique identifiée ou identifiable (personne concernée) ; est réputée&nbsp;identifiable une personne qui peut être identifiée, directement ou indirectement, notamment par référence à un nom, un numéro d'identification ou à un ou plusieurs éléments spécifiques, propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les informations personnelles pouvant être recueillies sur le site sont principalement utilisées par l'éditeur pour la gestion des relations avec vous, et le cas échéant pour le traitement de vos commandes. </span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les données personnelles collectées sont les suivantes :</span></span></span></p>

<!---->

<!---->

<!---->

<!---->

<!---->

<!----><div _ngcontent-c23="" rl-text="content8">
<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;<span _ngcontent-c23="" class="boiler-field">nom de compte, mot de passe, entreprise projet par rapport a l'entreprise concernée</span></span></span></span></p>
</div>

<!---->

<!----><div _ngcontent-c23="" rl-text="content2">
<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Un délégué à la protection des données :&nbsp;<span _ngcontent-c23="" class="boiler-field">Bruno Benard</span>,&nbsp;<span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>, est à votre disposition pour toute&nbsp;question relative à la protection de vos données personnelles.</span></span></span></p>
</div>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="" style="">Article 8 - Droit d’accès, de rectification et de déréférencement de vos données</strong></span></span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;">&nbsp;</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">En application de la réglementation applicable aux données à caractère personnel, les utilisateurs disposent des droits suivants&nbsp;:</span></span></span></span></p>

<ul _ngcontent-c23="" style="">
	<li _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit d’accès&nbsp;: ils peuvent exercer leur droit d'accès, pour connaître les données personnelles les concernant, en écrivant à l'adresse électronique ci-dessous mentionnée. Dans ce cas, avant la mise en œuvre de ce droit, la Plateforme peut demander une preuve de l'identité de l'utilisateur afin d'en vérifier l'exactitude ;</span></span></span></span></li>
	<li _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;">
	<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit de rectification&nbsp;: si les données à caractère personnel détenues par la Plateforme sont inexactes, ils peuvent demander la mise à jour des informations ;</span></span></span></span></p>
	</li>
	<li _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;">
	<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit de suppression des données&nbsp;: les utilisateurs peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données ;</span></span></span></span></p>
	</li>
	<li _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;">
	<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit à la limitation du traitement&nbsp;: les utilisateurs peuvent de demander à la Plateforme de limiter le traitement des données personnelles conformément aux hypothèses prévues par le RGPD ;</span></span></span></span></p>
	</li>
	<li _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;">
	<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit de s’opposer au traitement des données&nbsp;: les utilisateurs peuvent s’opposer à ce que leurs&nbsp;données soient traitées conformément aux hypothèses prévues par le RGPD ;</span></span></span></span></p>
	</li>
	<li _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;">
	<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">le droit à la portabilité : ils peuvent réclamer que la Plateforme leur remette les données personnelles qu'ils&nbsp;ont fournies&nbsp;pour les transmettre à une nouvelle Plateforme.</span></span></span></span></p>
	</li>
</ul>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Vous pouvez exercer ce droit en nous contactant, à l’adresse suivante&nbsp;:</span></span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><!----><span _ngcontent-c23="" class="boiler-field">27 rue Gabriel Péri</span>&nbsp;<!----><span _ngcontent-c23="" class="boiler-field">94700 Maisons-Alfort</span><!----></span></span></span>&nbsp;<!----><!---->&nbsp;<!---->.</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;">&nbsp;</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Ou par email, à l’adresse&nbsp;:</span></span></span></p>

<!---->

<!---->

<!----><div _ngcontent-c23="" rl-text="content10">
<p _ngcontent-c23=""><span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>&nbsp; &nbsp;&nbsp;</p>
</div>

<!----><div _ngcontent-c23="" rl-text="content9">
<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Vous pouvez aussi vous adresser à notre&nbsp;délégué à la protection des données :&nbsp;<span _ngcontent-c23="" class="boiler-field">Bruno Benard</span>,&nbsp;<span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>, qui est à votre disposition pour toute&nbsp;question relative à la protection de vos données personnelles.</span></span></span></p>
</div>

<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Toute demande doit être accompagnée de la photocopie d’un titre d’identité en cours de validité signé et faire mention de l’adresse à laquelle l'éditeur pourra contacter le demandeur. La réponse sera adressée dans le mois suivant la réception de la demande. Ce&nbsp;délai d'un mois peut être prolongé de deux mois si la complexité de la demande et/ou le nombre de demandes l'exigent.</span></span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;">&nbsp;</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">De plus, et depuis la loi n°2016-1321 du 7 octobre 2016, les personnes qui le souhaitent, ont la possibilité d’organiser le sort de leurs données après leur décès. Pour plus d’information sur le sujet, vous pouvez consulter le site Internet de la CNIL&nbsp;:&nbsp;https://www.cnil.fr/.</span></span></span></p>

<p _ngcontent-c23="">&nbsp;</p>

<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">Les utilisateurs peuvent aussi introduire une réclamation auprès de la CNIL sur le site de la CNIL&nbsp;: </span></span><a _ngcontent-c23="" href="https://www.cnil.fr" style=""><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">https://www.cnil.fr</span></span></a><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">. </span></span></span></span></p>

<p _ngcontent-c23="">&nbsp;</p>

<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">Nous vous recommandons&nbsp;de nous contacter&nbsp;dans un premier temps&nbsp;avant de déposer une réclamation auprès de la CNIL, car nous sommes à votre entière disposition pour régler votre problème. </span></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 9 - Utilisation des données</strong></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">Les données personnelles collectées auprès des utilisateurs ont pour objectif la mise à disposition des services de la Plateforme, leur amélioration et le maintien d'un environnement sécurisé. La base légale des traitements est &nbsp;l’exécution du contrat entre l’utilisateur et la Plateforme. Plus précisément, les utilisations sont les suivantes&nbsp;:</span></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- accès et utilisation de la Plateforme par l'utilisateur&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- gestion du fonctionnement et optimisation de la Plateforme&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- mise en œuvre d'une assistance utilisateurs&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- vérification, identification et authentification des données transmises par l'utilisateur&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- personnalisation des services en affichant des publicités en fonction de l'historique de navigation de l'utilisateur, selon ses préférences&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- prévention et détection des fraudes, malwares (malicious softwares ou logiciels malveillants) et gestion des incidents de sécurité&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- gestion des éventuels litiges avec les utilisateurs&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- envoi d'informations commerciales et publicitaires, en fonction des préférences de l'utilisateur ;</span></span></span></span></p>

<!---->

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 10 - Politique de conservation des données</strong></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" dir="ltr" style="text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">La Plateforme conserve vos données pour la durée nécessaire pour vous fournir ses services ou son&nbsp;assistance. </span></span></span></span></p>

<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">Dans la mesure raisonnablement nécessaire ou requise pour satisfaire aux obligations légales ou réglementaires, régler des litiges, empêcher les fraudes et abus ou appliquer nos modalités et conditions, nous pouvons également conserver certaines de vos informations si nécessaire, même après que vous ayez fermé votre compte ou que nous n'ayons&nbsp;plus besoin pour vous fournir nos&nbsp;services.</span></span></span></span></p>

<p _ngcontent-c23="">&nbsp;</p>

<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23=""><span _ngcontent-c23="" style="font-style: normal;">Article 11- Partage des données personnelles avec des tiers</span></strong></span></span></span></p>

<p _ngcontent-c23="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">Les données personnelles peuvent être partagées avec des sociétés tierces exclusivement dans l’Union européenne, dans les cas suivants&nbsp;:</span></span></span></span></p>

<!---->

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- lorsque l'utilisateur publie, dans les zones de commentaires libres de la Plateforme, des informations accessibles au public&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- quand l'utilisateur autorise le site web d'un tiers à accéder à ses données&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- quand la Plateforme recourt aux services de prestataires pour fournir l'assistance utilisateurs, la publicité et les services de paiement. Ces prestataires disposent d'un accès limité aux données de l'utilisateur, dans le cadre de l'exécution de ces prestations, et ont l'obligation contractuelle de les utiliser en conformité avec les dispositions de la réglementation applicable en matière protection des données à caractère personnel&nbsp;;</span></span></span></span></p>

<p _ngcontent-c23="" dir="ltr" style="color: rgb(0, 0, 0); font-style: normal;text-align: justify;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><span _ngcontent-c23="" style="font-style: normal;">- si la loi l'exige, la Plateforme peut effectuer la transmission de données pour donner suite aux réclamations présentées contre la Plateforme et se conformer aux procédures administratives et judiciaires.</span></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 12&nbsp;- Offres commerciales</strong></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Vous êtes susceptible de recevoir des offres commerciales de l'éditeur. Si vous ne le souhaitez pas, veuillez cliquer sur le lien suivant&nbsp;: <span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Vos données sont susceptibles d’être utilisées par les partenaires de l'éditeur à des fins de prospection commerciale, si vous ne le souhaitez pas, veuillez cliquer sur le lien suivant&nbsp;: <span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span>.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Si, lors de la consultation du site, vous accédez à des données à caractère personnel, vous devez vous abstenir de toute collecte, de toute utilisation non autorisée et de tout acte pouvant constituer une atteinte à la vie privée ou à la réputation des personnes. L'éditeur décline toute responsabilité à cet égard.</span></span></span></p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les données sont conservées et utilisées pour une durée conforme à la législation en vigueur.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 13&nbsp;-&nbsp;Cookies</strong>&nbsp;</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Qu’est-ce qu’un «&nbsp;cookie&nbsp;»&nbsp;?</span></span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Un «&nbsp;Cookie&nbsp;» ou traceur est un fichier électronique déposé sur un terminal (ordinateur, tablette, smartphone,…) et lu par exemple lors de la consultation d'un site internet, de la lecture d'un courrier électronique, de l'installation ou de l'utilisation d'un logiciel ou d'une application mobile et ce, quel que soit le type de terminal utilisé&nbsp;<span _ngcontent-c23="" style="font-style: normal; text-align: start;">(source&nbsp;:&nbsp;</span></span><a _ngcontent-c23="" href="https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi" style="color: rgb(148, 88, 23); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="color:#000000;">https://www.cnil.fr/fr/cookies-</span>traceurs-que-dit-la-loi</a><span _ngcontent-c23="" style="font-style: normal; text-align: start;">)</span>.</span></span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-style: normal; text-align: start;">Le site peut collecter automatiquement des informations standards. Toutes les informations collectées indirectement ne seront utilisées que pour suivre le volume, le type et la configuration du trafic utilisant ce site, pour en développer la conception et l'agencement et à d'autres fins administratives et de planification et plus généralement pour améliorer le service que nous vous offrons.</span></p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;">&nbsp;</p>

<p _ngcontent-c23="" style="color: rgb(82, 82, 82); font-style: normal; text-align: start;"><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Le cas échéant, des «&nbsp;cookies&nbsp;» émanant de l'éditeur du site&nbsp;et/ou des sociétés tiers pourront être déposés sur votre terminal, avec votre accord. Dans ce cas, l</span></span></span><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">ors de la première navigation sur ce site, une bannière explicative sur l’utilisation des «&nbsp;cookies&nbsp;» apparaîtra. Avant de poursuivre la navigation, le client et/ou le prospect devra accepter ou refuser&nbsp;l’utilisation desdits «&nbsp;cookies&nbsp;». Le consentement donné sera valable pour une période de treize (13) mois.&nbsp;<span _ngcontent-c23="" style="font-style: normal;">L'utilisateur a la possibilité de désactiver les cookies à tout moment.</span></span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les cookies suivants sont présents sur ce site :&nbsp;</span></span></span></p>

<!---->

<!---->

<!---->

<div _ngcontent-c23="">
<!----><div _ngcontent-c23="" rl-text="content1">
<div _ngcontent-c23="">
<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">-&nbsp;<span _ngcontent-c23="" class="boiler-field">none</span>.</span></span></span></p>
</div>
</div>

<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">&nbsp;&nbsp;</span></span></span></p>
</div>

<p _ngcontent-c23=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">La&nbsp;durée de vie de ces cookies est de treize mois.</span></span></span></p>

<p _ngcontent-c23=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 14&nbsp;-&nbsp;Photographies et représentation des produits</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les photographies de produits, accompagnant leur description, ne sont pas contractuelles et n'engagent pas l'éditeur.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 15&nbsp;-&nbsp;Loi applicable</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Les présentes conditions d'utilisation du site sont régies par la loi française et soumises à la compétence des tribunaux du siège social de l'éditeur, sous réserve d'une attribution de compétence spécifique découlant d'un texte de loi ou réglementaire particulier.</span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;"><strong _ngcontent-c23="">Article 16&nbsp;-&nbsp;Contactez-nous</strong></span></span></span></p>

<p _ngcontent-c23="" style=""><br _ngcontent-c23="">
<span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color:#000000;">Pour toute question, information sur les produits présentés sur le site, ou concernant le site lui-même, vous pouvez laisser un message à l'adresse suivante&nbsp;:&nbsp;<!----><span _ngcontent-c23="" class="boiler-field">contact@ducre.net</span><!----><!---->.&nbsp; &nbsp; &nbsp;</span></span></span></p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style="">&nbsp;</p>

<p _ngcontent-c23="" style=""><span _ngcontent-c23="" style="font-size:16px;"><span _ngcontent-c23="" style="font-family:Times New Roman,Times,serif;"><span _ngcontent-c23="" style="color: rgb(23, 43, 77); font-style: normal; text-align: start;">Ces </span><a _ngcontent-c23="" data-renderer-mark="true" href="https://www.rocketlawyer.com/fr/fr/document/site-internet-mentions-legales-et-politique-de-confidentialite" style="color: rgb(0, 82, 204); font-style: normal; text-align: start;" title="https://www.rocketlawyer.com/fr/fr/document/site-internet-mentions-legales-et-politique-de-confidentialite">mentions légales et politique de confidentialité</a><span _ngcontent-c23="" style="color: rgb(23, 43, 77); font-style: normal; text-align: start;"> ont été créées sur le site Rocket Lawyer.</span></span></span></p>
</ng-component>
  </div>
</div>
</template>