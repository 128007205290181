<template>
    <div>
        <form @submit="reset" class="flexbox column">
        <input
          required
          minlength="1"
          class="logInput"
          type="password"
          id="passe"
          name="passe"
          placeholder="Mot de passe"
          v-model="password"
        />
        <input
          required
          class="logInput"
          type="password"
          id="passeConf"
          name="passeConf"
          placeholder="Confirmation"
          v-model="ConfirmPassword"
        />
        <div id="buttons" class="flexbox row logButtons">
          <span v-if="!check">les mots de passe ne correspondent pas !</span>
          <input
            v-if="check"
            class="button"
            type="submit"
            value="reset"
          />
          
          <!-- <RouterButton :path="path" :name="name"/> -->
        </div>
        </form>
    </div>
</template>
<script>
import axios from "axios"
export default {
    name:"Reset",
    data(){
        return {
            password:"",
            ConfirmPassword:""
        }

    },
    methods: {
        async reset(e){
            e.preventDefault()
            await axios.post("/user/resetPass",{password:this.password},{headers:{'x-access-token':this.$route.query.token}}).then( (res)=>{
                if(res.status==200){
                    this.$router.push("/");
                }
            })
            
        }
    },
    computed: {
        check(){
            return this.password==this.ConfirmPassword
        }
    }
}
</script>