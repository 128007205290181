<template>
<div>
<div v-if="isConnected">
  <div >
                <a v-on:click="Account()" href="javascript: void(0)">Mon compte</a>&nbsp;<a style ="color: #B51032">Nom de Compte : {{name}}</a></div>
                        <br/>
                <a v-on:click="Disconnect()" href="javascript: void(0)">Se déconnecter</a>
                    
</div>
<div v-else>
                <a v-on:click="Login()" href="javascript: void(0)">S'identifier</a>
                        <br/>
                <router-link to="/User/Sign">S'inscrire</router-link>      
</div>
  

</div>
</template>
<script>
import axios from "axios"
import store from "../store"
//import { watch } from '@vue/runtime-core';
//import jwt from "jsonwebtoken"
export default {
  name: "Inscription",
  components: {},
  data() {
    return {
      connected:false,
      name:""
    };
  },
  methods : {
    async Disconnect(){
      store.state.co=false;
        await axios.post("/user/Disconnect",{},{headers:{ 'x-access-token':store.state.token }}).then((res) => {
          if(res.status=="200"){
            this.$router.push("/")
          }else {
            this.$notify({
              title:"serveur",
              message:"erreur erreur bip bip"
          })
          }
        })
    },
    async Account(){
      console.log(store.state.token)
      await axios.get("/admin/validate",{headers:{ 'x-access-token':store.state.token }}).then((res) => {
      if(res.status==200){
        this.$router.push("/User/Admin");
      }else {
        this.$router.push("/User/Account");
      }
      })
    },
    Login(){
        this.$router.push("/User/Login");
    }
  },
  computed:{
    isConnected(){
       return store.state.co
    }
  },
  watch: {
      isConnected(){
        if(this.isConnected){
        this.name=store.state.user.name
        }else {
          this.name=""
        }
      }
    }
  

}
</script>
