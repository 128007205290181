import { createRouter, createWebHistory } from 'vue-router'
import Accueil from '../views/Accueil.vue'
import Machines from '../views/Machines_Speciales.vue'
import Bancs from '../views/Banc_essai.vue'
import Automatisme from '../views/Automatisme.vue'
import Informatique from '../views/Informatique_Industrielle.vue'
import ArmoireElec from '../views/Armoire_Electrique.vue'
import EtudeMeca from '../views/Etude_Mecanique.vue'
import EtudeElec from '../views/Etude_Electrique.vue'
import EtudeAutoma from '../views/Etude_Automatisme.vue'
import OutillageSpec from '../views/Outillage_Specifique.vue'
import Routage from "../views/Routage.vue"
import ChariotSpec from '../views/Chariot_Specifique.vue'
import OutilsControlesMesures from '../views/Outils_controles_mesures.vue'
import Universcience from '../views/Universcience.vue'
import Presentation from '../views/About_Presentation.vue'
import Galerie from '../views/Galerie.vue'
import Galerie_Image from '../views/Galerie_Image.vue'
import Sign from '../views/Inscription.vue'
import Login from '../views/Connexion.vue'
import Account from '../views/Account.vue'
import Admin from '../views/Admin_Account.vue'
import MentionsLegales from '../components/LegalMentions.vue'
import Reset from '../views/passReset.vue'
import mail from "../views/mailRecovery.vue"
import Science from "../views/Science-Ducre.vue"
import hello from "../components/hello.vue"
/*let directory = '../views'
const components = require.context('../views', true, /\.vue$/);
console.log(components.keys())
let keys = components.keys()
//components.keys().forEach(components);
let test = components .keys().map(x => components (x))
console.log(test)
console.log(components("./About_Presentation.vue").default.render)
keys[1]=keys[1].substring(1)
/*const routes2 = []
for (let a in keys){
  let b=a.substring(1)
  routes2.push({
    path:b,
    name:a,
    component:() => import( directory+b)
  })
}*/
const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: Accueil
  },
  {
    path: '/Solutions_Industrielles/Machines_speciales',
    name: 'Machines spéciales',
    component: Machines
  },
  {
    path: "/Solutions_Industrielles/Banc_d'essai",
    name: 'Banc_essai',
    component: Bancs
  },
  {
    path: '/Solutions_Industrielles/Automatisme',
    name: 'Automatisme',
    component: Automatisme
  },
  {
    path: '/Solutions_Industrielles/Informatique_Industrielle',
    name: 'Informatique',
    component: Informatique
  },
  {
    path: '/Solutions_Industrielles/Armoire_Electrique',
    name: 'ArmoireElec',
    component: ArmoireElec
  },
  {
    path: '/Services/Etude_Mecanique',
    name: 'EtudeMeca',
    component: EtudeMeca
  },
  {
    path: '/Services/Etude_Electrique',
    name: 'EtudeElec',
    component: EtudeElec
  },
  {
    path: '/Services/Etude_Automatisme',
    name: 'EtudeAutoma',
    component: EtudeAutoma
  },
  {
    path: '/Produits/Outillage_Specifique',
    name: 'OutillageSpec',
    component: OutillageSpec
  },
  {
    path: '/Produits/Chariot_Specifique',
    name: 'ChariotSpec',
    component: ChariotSpec
  },
  {
    path: '/Produits/Outils_de_Controle/Mesure',
    name: 'OutilsControlesMesures',
    component: OutilsControlesMesures
  },
  {
    path: '/Universcience/Universcience',
    name: 'Universcience',
    component: Universcience
  },
  {
    path: '/A_propos_de/Presentation',
    name: 'Presentation',
    component: Presentation
  },
  {
    path: '/A_propos_de/Galerie',
    name: 'Galerie',
    component: Galerie
  },
  {
    path: '/About/Galerie_Image',
    name: 'Bouteille',
    component: Galerie_Image
  },
  {
    path: '/User/Sign',
    name: 'Sign',
    component: Sign
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/User/Login',
    name: 'Login',
    component: Login
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/User/Account',
    name: 'Account',
    component: Account
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/legal',
    name: 'MentionsLegales',
    component: MentionsLegales
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/User/Admin',
    name: 'Admin',
    component: Admin
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/reset-password',
    name: 'Reset',
    component: Reset
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/mail',
    name: 'mail',
    component: mail
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/Universcience/Cite_des_Sciences',
    name: 'Science',
    component: Science
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/Universcience/Routage',
    name: 'Routage',
    component: Routage
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  {
    path: '/Hello',
    name: 'Hello',
    component: hello
  },//* /../assets/Images/CDS/CDS-Pong.jpg
  /*{
    path: '/../assets/Images/CDS/CDS-Pong.jpg',
    name: 'Bouteille',
    component: img
  },//*/
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  mode:"hash",
  routes
})

export default router
