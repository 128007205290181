<template>
  <div id="Content">
    <div class="flexbox column interfaceLog">
      <h1 class="titre">Inscription:</h1>

      <form @submit="onInscription" class="flexbox column">
        <input
          class="logInput"
          type="text"
          id="email"
          name="email"
          placeholder="Email"
          v-model="user.mail"
        />
        <input
          required
          pattern="[a-zA-Z]+"
          minlength="1"
          class="logInput"
          type="text"
          id="utilisateur"
          name="utilisateur"
          placeholder="Nom d'utilisateur"
          v-model="user.username"
        />
        <input
          required
          minlength="1"
          class="logInput"
          type="password"
          id="passe"
          name="passe"
          placeholder="Mot de passe"
          v-model="user.password"
        />
        <input
          required
          class="logInput"
          type="password"
          id="passeConf"
          name="passeConf"
          placeholder="Confirmation"
          v-model="ConfirmPassword"
        />
        <input
          required
          pattern="[a-zA-Z]+"
          minlength="1"
          class="logInput"
          type="text"
          id="Enterprise"
          name="Enterprise"
          placeholder="Entreprise"
          v-model="user.Enterprise"
        />
        <div id="buttons" class="flexbox row logButtons">
          <span v-if="!check">les mots de passe ne correspondent pas !</span>
          <input
            v-if="check"
            class="button"
            type="submit"
            value="Inscription"
          />
          <!-- <RouterButton :path="path" :name="name"/> -->
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import RouterButton from "../components/router-Button.vue"
//import store from "../store";
export default {
  name: "Inscription",
  components: {
    //RouterButton
    },
  data() {
    return {
      user: {
        username: "",
        password: "",
        mail: "",
        Enterprise:"",
      },
      ConfirmPassword: "",
      
      name:"Connexion",
      path:"/User/login"
    };
  },
  props: {},
  computed: {
    check() {
      return this.ConfirmPassword == this.user.password;
    },
  },
  methods: {
    async onInscription(e) {
      e.preventDefault();
      await axios
        .post("/user/register", this.user)
        .then((res) => {
            this.$router.push('/User/Login')
            //store.state.NavBar.methods.popup(res.data.message);
            console.log(res.data.message);
        }, ()=>{
          //store.state.NavBar.methods.popup("username déja utilisé");
          console.log(this.user.Enterprise);
        });

      window.scrollTo(0, 0);
    },
  },
};
</script>