<template>
<div class="column">
<div id="range" style="width:1100px;margin-left: auto;margin-right: auto;">
    <div id="Jeu_video">
        &nbsp;&nbsp;
    <h1>Exposition JEU VIDEO à la cité des sciences :
    </h1>
        <div class="droite">
            "
        <img src="../assets/Images/CDS/JeuVideo.jpg" alt="Expo Jeu vidéo" style="height: 132px; width: 108px" />
        </div>
        <br />
<div>
        &nbsp;&nbsp;&nbsp;
    Pendant l'inauguration de l'exposition "Jeu Vidéo", Madame Fleur Pellerin, Ministre délégué chargée des PME,de l'innovation et l'Economie numérique affronte Madame Aurélie Filippetti, Ministre de la culture et de la communication avec la machine DUCRE.  
</div> <br />
        <br />

        <p>

            <iframe height="315" src="//www.youtube.com/embed/pN2BKApVdyo?rel=0" style="width: 466px; margin-left: 180pt; margin-top: 0px"></iframe>
        </p>
    </div>
    <div id="Image_pong">
        <img id="ctl00_MainContent_Image1" class="Image1" src="../assets/Images/CDS/CDS-Pong.jpg" alt="Image pong" align="middle" style="height:206px;width:275px;border-width:0px;" />

        <br />
        La machine "PONG" est un ensemble mécanique trois axes reproduisant le premier jeu "pong".
         <br />
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;L'ensemble est visible à la cité des sciences dans l'espace jeu vidéo du E-Lab au 3ème étage.
         <br />

        &nbsp;
        <div class="decale">
            <div class="photo_type gauche">
                <img src="../assets/Images/CDS/Ensemble_Pong.jpg" alt="Expo Jeu vidéo" style="height: 182px; width: 281px" /><br />
                <p class="titre_photo" style="width: 226px">Plan Machine pong en 3D"</p>
            </div>
        </div>
    </div>


    <h1>Nous avons livré plusieurs postes pour des expositions temporaires ou permanentes de la cité des sciences.
    </h1>
    <br />
    </div>
    <div>
        <div class="polaroid rotation_gauche7">
            <img src="../assets/Images/CDS/CDS-Orbitogramme.jpg" alt="" style="height: 181px; width: 246px" />
            <p class="caption">Orbitogramme : Simulation de mouvement d'orbite.</p>
        </div>

      <div class="polaroid rotation_droite8 droite">
          <img src="../assets/Images/CDS/CDS-Brachistochrone.jpg" alt="" style="height: 184px; width: 248px" />
          <p class="caption">Brachistochrone : Chemin le plus rapide.</p>
      </div>
        <div class="polaroid rotation_gauche7">
            <img src="../assets/Images/CDS/CDS-Recipient.jpg" alt="" style="height: 178px; width: 213px" />
            <p class="caption">Remplissage des réservoirs.</p>
        </div>

        <br />

        <div class="polaroid rotation_droite8">
            <img src="../assets/Images/CDS/CDS-Pythagore.jpg" alt="" style="height: 165px; width: 226px" />
            <p class="caption">Théorème de pythagore.</p>
        </div>
    </div>


    <br />
</div>
</template>