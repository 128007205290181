<template>
<div >
<div class="partie1" style="margin-top:50px;">
        <div class="photo_type droite ovale" style="position:relative;right:0%;top:0px;">
            <img src="../assets/Images/CAO/Etude-Mise_en_plan2.jpg" alt="Mise en plan" style="height: 221px; width: 246px" />

            <p class="titre_photo" style="width: 226px">Mise en plan de pièce"</p>
        </div>

        <div class="texte gauche" style="width: 407px; height: 229px; left: 1px; top: 10px;">

            <h1>Nos études mécaniques englobent les domaines suivants :
            </h1>
            <ul>

                <li>Outillage et équipements de fabrication</li>
                <li>Montage d'usinage</li>
                <li>Equipement d'usinage électro-chimique</li>
                <li>Equipement d'usinage électrolitique</li>
                <li>Montage d'assemblage et de soudage</li>
                <li>Outillage de presse</li>
                <li>Outil de reprise (poinçonnage, découpage et emboutissage)</li>
                <li>Outil progressif</li>
                <li>Ligne de poinçonnage, découpe et emboutissage</li>
                <li>Equipement d'automatisme de presse</li>
                <li>Estampage</li>
                <li>Assemblage de pièce</li>
                <li>Contrôle</li>
            </ul>


        </div>
    </div>
    <div class="partie2 row" style="height:370px;">
        <div class="photo_type gauche ovale" style="position:relative;">
            <img src="../assets/Images/CAO/Etude-Assemblage.jpg" alt="Machine complète" style="height: 267px; width: 395px" />
            <p class="caption">Machine d'assemblage et contrôle</p>
        </div>

        <div class="texte droite"  style="height: 267px; width: 355px" >
            <h1>Nous sommes intervenus dans les domaines de :
            </h1>

            <ul>
                <li>Assemblage de pièces</li>
                <li>Tri de pièces</li>
                <li>contrôle qualité</li>
                <li>Positionnement à haute vitesse</li>
                <li>Machine haute cadence</li>
            </ul>
        </div>
 </div>

      
        <div class="partie3" style="margin-top:50px">
            <div class="photo_type droite ovale" style="position:relative;right:0%;top:0px;">
                <img src="../assets/Images/CAO/Etude-Montage_soudage2.jpg" alt="Exemple d'étude de montage" style="height: 255px; width: 273px;" />
                <p class="caption">Exemple de pièce</p>
            </div>

            <div class="texte gauche" style="width: 400px; height: 265px;">
                <h1>
                    Le bureau d'étude réalise des plans en 2D ou 3D. 
                    <br /> 
                    Ces pièces peuvent être fabriquées par les ateliers de
                    <br />
                    soudures, tolerie ou mécano-soudure.
                    <br />
                   
 

                </h1>
            </div>
        </div>
</div>
</template>