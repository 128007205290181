<template>
<div class ="row" style ="justify-content:space-evenly">
    <div class="column gauche" style="height: 866px;width:232px;">
        <div class="photo_type droite  arrondi" >
            <img src="../assets/Images/CAO/Schema-VUE_ARMOIRE.jpg" alt="Vue armoire" style="height: 297px; width: 210px" />
            <p class="caption">Vue intérieure armoire</p>
        </div>
    
        <div class="texte g" style="width: 300px; height: 265px;margin-left:-50px">
            <h1>Les armoires sont réalisées suivant votre cahier des charges.
                  <br />
                Nous vous aidons à rédiger des analyses fonctionnelles détaillées.
                  <br />
                L'ensemble des schémas est réalisé sous DAO et inclut :
                   <ul>
                       <li>Les nomenclatures</li>
                       <li>Les schémas de distribution, commande et puissance </li>
                       <li>Les implantations</li>
                       <li>Le descriptif des borniers avec les carnets de câble.</li>
                   </ul>
            </h1>
        </div>
    </div>
    <div class="colonne droite">
        <div class="photo_type gauche arrondi" >
            <img src="../assets/Images/CAO/Schema-Bornier.jpg" alt="Borniers" style="height: 297px; width: 210px" />
            <p class="caption">Borniers de liaison</p>
        </div>

        <div class="texte g">
            <h1>Les études sont adaptées aux normes requises par le projet 
            </h1>
          
            <ul>
                <li>Environnement ADF - Ex</li>
                <li>Norme CEM</li>
                <li>Norme NF C 15-100 / XP C 16-600</li>
                <li>Armoire marine</li>
            </ul>
        </div>


        <br />
    </div>
    
    <div class="colonne droite" style="height: 866px;">
        <div class="photo_type gauche arrondi" >
            <img src="../assets/Images/CAO/Schema-SORTIE_AUTOMATE.jpg" alt="Carte Automate" style="height: 297px; width: 210px" />
            <p class="caption">Carte automate</p>
        </div>

        <div class="texte g" style="width: 250px; height: 265px;">

            <h1>Nos études incluent :
            </h1>

            <ul>

                <li>le choix des matériels</li>
                <li>les calculs nécessaires au dimensionnement</li>
                <li>les schémas électriques</li>
                <li>la liste des câbles et chemins de câbles</li>
                <li>la nomenclature de l"ensemble des matériels</li>
                <li>la définition des encombrements et face avant d'armoires</li>
                <li>une aide à la conception pour nos clients</li>
            </ul>

        </div>
    </div>
</div>
</template>