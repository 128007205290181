import Vuex from 'vuex'//variable globales temporaires (tant que le serveur dure)

export default new Vuex.Store({
    state: {},
    mutations: {
        "<MUTATION_PREFIX><EVENT_NAME>"() {
            // do something
        }
    },
    actions: {
        "<ACTION_PREFIX><EVENT_NAME>"() {
            // do something
        }
    }
})