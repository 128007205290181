import { createApp } from 'vue'
import Vuex from 'vuex'
import store from './store'
import App from './App.vue'
import router from './router'
import axios from 'axios'
//import * as bootstrap from 'bootstrap';
axios.defaults.baseURL = 
//"http://pok15.freeboxos.fr:34000/api"
//"http://localhost:9000/api"
"https://celene-sarl.com/api"
//import VuePictureSwipe from '@itadori/vue-picture-swipe-control';
import Lightbox from 'vue-my-photos'
import Notifications from '@kyvg/vue3-notification'


//Initialisation des variables globales
store.state.co=false
store.state.site = 
"ducre.net"
//"cepram.com"
store.state.img=""
const app =createApp(App)

app.use(Notifications)

axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    app._component.methods.toMessage("Request Server done",response.data.message)
    return response;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.log(error)
    app._component.methods.toMessage(error,error.response.data.error)
    return Promise.reject(error);
  });//*/
//app.use(axios)

app.use(Vuex)
app.use(router)
app.use(store)
app.use(Lightbox)
//app.use(Photoswipe)
//app.use(VueSilentbox)
app.mount('#app')
