<template>
    <div>
    <div class="partie1">
        <div class="photo_type droite ovale" style="width: 216px; height: 272px;">

            <img src="../assets/Images/Machine/Machine-assemblage.jpg" alt="Assemblage pièce" style="height: 180px; width: 233px" />
            <img id="ctl00_MainContent_Image1" class="logo2" src="../assets/logo_ducre.gif" align="middle" style="border-style:Dotted;border-width:0px;" />

            <p class="caption">Machine assemblage et contrôle automatique</p>
        </div>

        <div class="texte gauche">

            <h1>Nous réalisons des machines spéciales sur demande :
            </h1>
            <ul>

                <li>système continu ou discontinu</li>
                <li>emboutissage</li>
                <li>estampage</li>
                <li>presse</li>
                <li>assemblage</li>
                <li>contrôle</li>
            </ul>


        </div>
    </div>
    <div class="partie2 row">
        <div class="photo_type gauche ovale">
            <img src="../assets/Images/Robot/Robot.jpg" alt="Robot de manipulation" style="height: 171px; width: 232px" />
            <p class="caption">Robot de manipulation</p>
        </div>

        <div class="texte droite" style="width: 650px; height: 200px;">
            <h1>Chaque réalisation est spécifique. Par exemple :
         <br />
                Nous intervenons dans les domaines de :
            </h1>

            <ul>
                <li>Assemblage de pièces</li>
                <li>Tri de pièces</li>
                <li>contrôle qualité</li>
                <li>Positionnement à haute vitesse</li>
            </ul>
        </div>

    </div>
        <br />
        <div class="partie3">
            <div class="photo_type droite ovale">
                <img src="../assets/Images/Machine/Machine-TDV.jpg" alt="Machine de contrôle" style="width: 273px;" />
                <p class="caption">Machine de contrôle pièce</p>
            </div>

            <div class="texte gauche">

                <br />
                <h1>Les machines peuvent être:
                </h1>
                <ul>
                    <li>automatiques</li>
                    <li>semi-automatiques</li>
                    <li>manuelles</li>
                </ul>
                <br />
                Les machines peuvent être linéaires ou rotatives.  
                    
         <br />



            </div>
        </div>

            
    </div>
    </template>