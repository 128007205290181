<template>
    <div>
         <div>
         <h1 style="color : rgb(0,0,255);font-size:1.6em">Routage - Espace informatique et science du numérique</h1>
        <p style="width:1000px; text-align:justify">
            Le projet routage pour le Palais de la découverte consiste à expliquer et montrer le phénomène de routage.
            en informatique, le terme routage désigne le mécanisme par lequel les données d'un équipement expéditeur sont acheminées jusqu'à leur destinataire en examinant les informations.
            même si aucun des deux ne connaît le chemin complet que les données devront suivre. Avoir une procédure de routage efficace est particulièrement important pour les réseaux décentralisés.
            La table de routage est composée de la liste des réseaux connus, chacun de ces réseaux est associé à un ou plusieurs routeurs voisins qui vont acheminer les paquets vers cette destination.
            Ainsi de routeur en routeur le routage aide à déterminer quelle est la meilleure route disponible à prendre pour transférer les données jusqu'à la cible.
        
        </p>
        <img src="../assets/routage.jpg" />
    </div>
    </div>
</template>