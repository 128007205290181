<template>
<div>
<div v-if="Loading">
    <img src="../assets/Images/load.png" style="width:50px"/> Chargement en cours merci de bien vouloir 
    patienter quelques instants
</div>
    <div v-if="allowed">
        <a class="Fbutton" @click="update()">UPDATE</a>
        <upload-file :admin="true" :items2="CurrentListOfFolder" :last="lastFolder"/>
        <add-project :items2="enterprises" />
        <radio-buttons :RadioButtons="listRadio" :Title="Title" :f="choose"/>
        <a class="Fbutton" @click="accountManager.updateFolders()" >UpdateFolders</a>
        <a v-if="isRoot" class="Fbutton" @click="accountManager.change('../')" >../</a>
        <a v-if="isRoot" class="Fbutton">CurrentFolder : {{path}}</a>
        <a v-else class="Fbutton">CurrentFolder : ./</a>
        <div class="colu">
            <div v-for="files in folder" :key="files" :folder="files" class="block2">
                <file v-for="f in files" :key="f" :folder="f"/>
            </div>
        </div>
        <AccountModify/>
        <div class="historique flexbox column">
            <div class="ligne">
            <button class="button" @click="accountManager.seePeoplesNotValidated()">See Unknown Peoples</button>
            <button class="button" @click="accountManager.seePeoplesValidated()">See Known Peoples</button>
            <button class="button" @click="accountManager.update()">Update</button>
            </div>
            <table v-if="seePeople">
            <tr>
                <td class="h-blanc">name</td>
                <td class="h-noir">Enterprise</td>
                <td class="h-autre">known</td>
                <td class="h-autre">Action</td>
            </tr>
                <People v-for="people in peoples" :key="people" :people="people" :EnterpriseList="enterprises" />
            </table>
        </div>
    </div>
</div>
</template>
<script>
import AccountModify from "../components/AccountModify.vue"
import People from "../components/peopleValidate.vue"
import axios from "axios"
import file from "../components/folder.vue"
import uploadFile from "../components/uploadFile.vue"
import RadioButtons from '../components/radio.vue'
import addProject from "../components/addProject.vue"
import store from "../store"
const account = require("../Functions/AccountFunctions.js")

//import store from "../store"
export default {
  name: "Profil",
  components: {
    AccountModify,People,file,uploadFile,
    RadioButtons,addProject
  },
  data() {
    return {
        admin:true,
      accountManager :new account.default(this),
      enterprises:{},
      Loading:false,
      seePeople:false,
      peopleValidated:[],
      peopleNotValidated:[],
      peoples:[],
      last:"",
      lastFolder:"",
      lastSelected:"",
      allowed:false,
      folder:["yopelait"],
      listOfFolder:{},
      path:"",
      CurrentListOfFolder:[],
      Title:"Delete or Download",
      delete:false,
      listRadio:[
        {
            value:"Delete"
        },
        {
            value:"Download"
        }
      ]
    };
  },
  async mounted() {
        await this.accountManager.update(this);
        await this.accountManager.validate(this);
        await this.accountManager.loadFolders(true);
        await this.accountManager.change("./",this);
        await this.loadEnterprises()
        //this.separate(this.folder).then((res)=>{console.log(res);this.folder=res})
  },
  methods: {
    async loadEnterprises(){
        await axios.get("/admin/enterprises",{headers:{ 'x-access-token':store.state.token }}).then((res)=>{
            this.enterprises = res.data.data
        })
    },
    change(f){
        this.accountManager.change(f,true)
    },
    getPath(){
      return this.path
    },
    async update(){
        await axios.get("/admin/updateAll",{headers:{ 'x-access-token':store.state.token }}).then((res)=>{
            console.log(res)
        })
    },
    choose(result){
        this.delete = result=="Delete"
    }
  },
    computed: {
        isRoot(){
            return this.lastFolder!="./"
        }
    }
};
</script>