<template>
<div>
<div class="partie1">
        <div class="photo_type droite carre">
            <img src="../assets/Images/Automatisme/Train.jpg" alt="Transport par chariot" style="height: 255px; width: 352px" />
            <p class="caption">Contrôle et assemblage de pièce</p>
            <p class="caption">avec transport par chariot</p>
        </div>

        <div class="texte gauche">

            <h1>Nous réalisons des automatismes pour :
            </h1>

            <ul>
                <li>Machine à plateau rotatif</li>
                <li>Machine à plateau linéaire</li>
                <li>Machine à transfert linéaire de chariot haute vitesse</li>
                <li>Procédé de fabrication continu et discontinu</li>
                <li>Pick and place</li>
                <li>Fabrication et Assemblage</li>
                <li>Contrôle qualité</li>
                <li>Gestion de ressousrce : Basculement, GTC , GTB</li>
                <li>Banc d'essai</li>
            </ul>
        </div>

    </div>
    <div class="partie2 row">
         <div class="texte droite" style="width: 250px;">
            <h1>
                <br />

                Nous réalisons des machines de contrôle :
            </h1>
          
            <br />
            <ul>
                <li>dimensionnels par palpeur</li>
                <li>de résistance</li>
                <li>d'isolement</li>
                <li>de forme, reconnaissance par caméra</li>
                <li>de varistance</li>
                <li>de couple</li>
                <li>...</li>
            </ul>
        </div>
        <div class="photo_type droite carre">
            <img src="../assets/Images/Robot/Robot-IR120.jpg" alt="Robot" style="height: 254px; width: 263px" />
            <p class="caption">Robot de manipulation</p>
        </div>

        <div class="texte gauche">
            <h1>
                <br />

                Nous sommes intervenus dans :
            </h1>
          
           
            <ul>
                <li>Aéronautique</li>
                <li>Automobile</li>
                <li>Agro-alimentaire</li>
                <li>Embouteillage</li>
                <li>Chimie</li>
                <li>Energie</li>
                <li>Médical et pharmacologie</li>
            </ul>
        </div>


    </div>
    <div class="partie3 row">
        <div class="photo_type gauche carre">
            <img src="../assets/Images/Automatisme/Hall-embouteillage.jpg" alt="Embouteillage" />
            <p class="caption">Hall d'embouteillage géré par automatisme SIEMENS</p>
        </div>

        <div class="texte droite">
              <br />  <br />
            <h1>Nos réalisations intégrent les automates : 
            </h1>

            <ul>
                <li style="width: 293px">SIEMENS, VIPA</li>
                <li style="width: 293px">SCHNEIDER</li>
                <li style="width: 293px">ROCKWELL</li>
                <li style="width: 294px">OMRON, YASKAMA</li>
                <li style="width: 293px">ABB</li>
                <li style="width: 293px">SAIA...</li>
            </ul>



        </div>

    </div>

    </div>

</template>