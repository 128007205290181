<template>

<div>
    <div class="flexbox row titres">
      <h1 class="titre">{{ this.nomActuel }}</h1>
    </div>
    <div class="modifCompte flexbox row">
      <div class="champCompte flexbox column">
        <div class="row">
          <a class="achampcompte">Nom d'utilisateur :</a>
        <input type="username" placeholder="Nom d'utilisateur" v-model="user.username"/>
        </div>
        <div class="row">
          <a class="achampcompte">Mot de passe :</a>
        <input type="password" placeholder="Mot de passe" v-model="user.password"/>
        </div>
      </div>
        <button class="button buttonRound" @click="modifier">Modifier</button>
    </div>
</div>
</template>

<script>
import store from "../store"
import axios from "axios";
export default {
  name: "Profil",
  components: {},
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      nomActuel: "",
    };
  },
  methods: {
    async modifier() {
      await axios.post(
        "user/users/" + store.state.user.name,
        {user:this.user},{headers:{ 'x-access-token':store.state.token }}
      ).then ((res)=> {
        if (this.user.username != "") {
        store.state.user.name = this.user.username;
        this.nomActuel = this.user.username;
      }
        if(res.status=="200"){
        this.$notify({
  title: "Changement de "+this.nomActuel,
  text: "changement bien pris en compte",
})}
      });
      
    }
  }
}
</script>
<style scoped>
@import "../css/style.css";
</style>