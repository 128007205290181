<template>
<div class="column">
    <div class="row">
        <div class="photo_type gauche carre">
            <img src="../assets/Images/CAO/Grafcet.jpg" alt="Grafcet" style="height: 254px; width: 263px" />
            <p class="caption">Grafcet</p>
        </div>

        <div class="texte r" style="width: 610px;">

            <h1>Nos études  s'appliquent dans :
            </h1>

            <ul>
                <li>Automatique industriel</li>
                <li>Mécatronique</li>
                <li>Interface Homme Machine</li>
                <li>Contrôle dimensionnel</li>
                <li>Contrôle électrique</li>
                <li>Contrôle par caméra</li>
                <li>Régulation de procédé : vitesse, température...</li>

            </ul>
        </div>



    </div>
    <div class="row">
        <div class="photo_type droite carre">
            <img src="../assets/Images/CAO/Schema-Variateur.jpg" alt="Régulation" style="height: 275px; width: 416px" />
            <p class="caption">Régulation de vitesse par variateur</p>
        </div>

        <div class="texte gauche" style="width: 500px">

            <h1>Nous pouvons intégrer l'ensemble de vos projets d'automatisme incluant :
            </h1>
            <ul>
                <li>Les armoires électriques</li>
                <li>la fourniture des automates</li>
                <li>la fourniture des capteurs et actionneur</li>
                <li>la programmation des automates</li>
                <li>la programmation informatique industrielle</li>
                <li>la mise au point sur site</li>
                <li>la "hot-line"</li>
                <li>le dépannage sur site</li>
            </ul>

        </div>

    </div>
    <div class="row">
        <div class="photo_type droite carre">
            <img src="../assets/Images/Machine/Amortisseur.jpg" alt="Amortisseur" style="height: 377px; width: 279px" />
            <p class="caption">Contrôle pression d'amortisseur</p>
        </div>



        <div class="texte gauche" style="width: 500px">
            <h1>Nous concevons des processus manuels, semi-automatiques ou entièrement automatisés.
                  <br />
                Les puissances peuvent être électriques, hydrauliques ou pneumatiques.
                                   <br />
                Nous vous aidons à la pré-étude de votre projet et à la rédaction de votre cahier des charges.
                 <br />
                Nous vous accompagnons jusqu'a la finalisation et la mise en service.
         <br />

            </h1>
        </div>

    </div>
</div>
</template>