<template>
<div>
      <input @change="f(result)" type="radio" v-model="result" :value="RadioButton.value" :name="name">
      <label :for="RadioButton.value">{{RadioButton.value}}</label>
    </div>
</template>
<script>
export default {
    data(){
    return {
            result:""
    }
    }
    ,
    props:{
        RadioButton:Object,
        name:String,
        f:Function
        },
    methods:{
        test2(){
            console.log("abd")
        }
    }
}
</script>