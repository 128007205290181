/* eslint-disable */
import store from "../store.js"
import axios from "axios"
//import { isProxy,toRaw } from "vue"
class Account {
    
    account
    listOfFolder={}
    last=""
    lastSelected=""
    admin=false

    constructor(acc){
        this.account=acc
    }

    async validate(){
        let account = this.account
        account.Loading=true
        console.log("validate admin")
        await axios
        .get("/admin/validate",{headers:{ 'x-access-token':store.state.token }})
        .then((res) => {
            account.Loading=false
            console.log(res);
            if(res.status=="200"){
                console.log("validate done")
                account.allowed=true;
                this.admin=true
            }})//*/
    }

    choose(result){
        let account = this.account
        if(result=="Delete"){
            account.delete=true
        }else {
            account.delete=false
        }
    }

    async updateFolders(){
        let account = this.account
        await this.loadFolders()
        if(account.lastFolder!=""){
            await this.change(account.lastFolder)
        }else {
            await this.change("./")
        }
    }

    async change(f,admin){
        let account = this.account
        
        if(f=="../"){
            let path2=account.path.split("/")
            f="/"+path2[path2.indexOf(account.lastFolder.slice(1))-1]
            if(f=="/"){f="./"}
            let lengthToRemove = account.path.length-account.lastFolder.length;
            account.path=account.path.slice(0,lengthToRemove)
        }
        else{
            if(f!="./" && f!=account.lastFolder){
                account.path+=f
            }
        }
        if(f.includes("./") || !f.includes(".")) {
            //console.log(this.listOfFolder[""])
            if(f.includes("./")){
                account.folder=this.listOfFolder[f]
            }else {
                console.log("acount.path : "+account.path)
                account.folder=this.listOfFolder[account.path]
            } 
            if(account.folder==undefined){
                account.folder=[]
            }
            account.CurrentListOfFolder = account.folder.filter((item) => {
            return (!item.includes("."))
        })
            this.separate(account.folder).then((res)=>{account.folder=res})
            account.lastFolder=f
        }
        else if(f.includes(".")){
            let complement="files"
            let finish
            
            if(admin){
                complement="admin"
                finish=""
            }else {
                finish="/"+store.state.user.enterprise+"/"
            }
            account.Loading=true
            if(account.delete){ 
                axios.post("/"+complement+"/file",{path:account.path},{headers:{ 'x-access-token':store.state.token }}).then((res) => {
                    account.Loading=false
                    if(res.status=="200"){
                        account.$notify("sucess")
                    }
                })
            }
            else {
            await axios.get("/"+complement+"/file"+finish+"?data="+account.path,{responseType:'blob',headers:{ 'x-access-token':store.state.token }}).then((res)=>{
                account.Loading=false
                if(res.status=="200"){
                    let FILE = window.URL.createObjectURL(new Blob([res.data]));   
                    var docUrl = document.createElement('a');
                     docUrl.href = FILE;
                     docUrl.setAttribute('download', f.slice(1));
                     document.body.appendChild(docUrl);
                     docUrl.click();
                     document.body.removeChild(docUrl)
                }
            })}
            account.path=account.path.slice(0,account.path.length-f.length)
        }
        this.lastSelected=f;
    }

    seeFiles() {
        let account = this.account
        account.seeFile = !account.seeFile
    }
    async separate(liste){
        let result={}
            for (let i=0;i<liste.length/3;i++){
                result[i]=[]
                for (let j=0;j<3;j++){
                    if(liste[i*3+j]){
                    result[i].push(liste[i*3+j])
                    }
                }
            }
            console.log("result : "+result)
            return result
    }
    async loadFolders() {
        let complement=""
        if(!this.admin){
            complement=store.state.user.enterprise
        }
        await axios.get("/files/files/"+complement,{headers:{ 'x-access-token':store.state.token }}).then((res)=> {
            if(res.status=="200"){
                console.log(res.data)
                this.listOfFolder = res.data.data
    }
})
}
async update(){
    let account = this.account
  await axios
    .get("/user/users",{headers:{ 'x-access-token':store.state.token }})
    .then((res) => {
        if(res.status=="200"){
          
    account.peoples = res.data.data;
    account.peopleValidated = account.peoples.filter((item) => {
        return (item.validated)
    });
    account.peopleNotValidated = account.peoples.filter((item) => {
        return (!item.validated)
    });
}
});  
}

seePeoples(v) {
    let account = this.account
    if(!(this.last!=v && account.seePeople)){
        account.seePeople = !account.seePeople
    }
    this.last=v;
}

seePeoplesValidated() {
    let account = this.account
    this.seePeoples("valid");
    account.peoples=account.peopleValidated;

}

seePeoplesNotValidated() {
    let account = this.account
    this.seePeoples("notValid");
    account.peoples=account.peopleNotValidated
}



async validateLog(){
    let account = this.account
    await axios
    .get("/user/validateLog",{headers:{ 'x-access-token':store.state.token }})
    .then((res) => { 
        if(res.status=="200"){
            console.log("validate done")
            account.allowed=true;
            account.isAllowed=true;
        }})
}
modifier() {
    let account = this.account
    axios.post(
      "/user/users/" + store.state.user.name,
      account.user,{headers:{ 'x-access-token':store.state.token }}
    );
    if (account.user.username != "") {
      store.state.user.name = account.user.username;
      account.nomActuel = account.user.username;
    }
  }
} 

export default Account