<template>
<div>
    <input type="text" v-model="mail"/>
    <button @click="recoverPassword">Mail password Recovery</button>
</div>
</template>
<script>
import axios from "axios"
export default {
    data(){
        return {
            mail:""
        }
    },
    methods: {
    async recoverPassword(){
      await axios.post("/user/user/checkRecovery",{mail:this.mail}).then((res) => {
        if(res.status==200){
            this.$router.push("/")
        }else {
            console.log("error")
        }
    })
    }
    }
}
</script>