<template>
                        <ul>
                            <li>
                                <router-link id="ctl00_lnkHome" class="a" to="/">Accueil</router-link>
                            </li>
                            
                                    <li v-for="(item,titles) in Menu.items" :key="item">
                                        <a class="a">{{titles}}</a>
                                                <ul>  
                                                <li v-for="items in item" :key="items">
                                                    <router-link class="a" :to="MakePath(titles,items)">{{items}}</router-link>
                                                </li>
                                                </ul>
                                    </li>
                                
                                    
                                
                        </ul>
</template>
<script>
export default {
  name: "Menu",
  components: {},
  data() {
    return {
      Menu:{
        Accueil:"/",
        items:{
            "Solutions Industrielles":[
                    "Machines spéciales",
                    "Banc d'essai",
                    "Automatisme",
                    "Informatique Industrielle",
                    "Armoire électrique"
                ],
                "Universcience":[
                "Universcience",
                    "Cité des Sciences",
                    "Routage"
            ],
            "Services":[
                    "Etude mécanique",
                    "Etude Electrique",
                    "Etude Automatisme"
                ],
            
            "Produits":[
                    "Outillage spécifique",
                    "Chariot spécifique",
                    "Outils de Contrôle/Mesure"
                    
                ],
            "A propos de":[
                    "Présentation",
                    "Galerie"
                ]
            }
      }
    };
  },
  mounted() {
  },
  methods: {
    MakePath(srcpart1,srcpart2){
        return "/"+this.convert(srcpart1)+"/"+this.convert(srcpart2)
    },
    deleteAcc(Stri){
    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ];
    var noaccent = ['A','a','E','e','I','i','O','o','U','u','N','n','C','c'];

    var str = Stri;
    for(var i = 0; i < accent.length; i++){
        str = str.replace(accent[i], noaccent[i]);
    }
return str;
},
    convert(toConvert){
        toConvert = this.deleteAcc(toConvert)
        let result=""
        for (let i=0;i<toConvert.length;i++){
            let letter = toConvert[i];
            if (letter == " "){
                letter="_"
            }
            result+=letter
        }
        return result;
    }
  }
}
</script>
