<template v-if="allowed">
  <div id="Content" v-if="allowed && isAllowed">
    <div v-if="Loading">
    <img src="../assets/Images/load.png" style="width:50px"/> Chargement en cours merci de bien vouloir 
    patienter quelques instants
</div>
     <upload-file :admin="false" :items2="CurrentListOfFolder" :last="lastFolder" :path="path"/>
        <a class="Fbutton" @click="accountManager.updateFolders(this)" >UpdateFolders</a>
        <a v-if="isRoot" class="Fbutton" @click="accountManager.change('../',this)" >../</a>
        <a v-if="isRoot" class="Fbutton">CurrentFolder : {{path}}</a>
        <a v-else class="Fbutton">CurrentFolder : ./</a>
        <div class="colu">
            <div v-for="files in folder" :key="files" :folder="files" class="block2">
                <file v-for="f in files" :key="f" :folder="f"/>
            </div>
        </div>
    <AccountModify/>

    <hr class="h-Bar barCompte" />
    <!--
    <div class="historique flexbox column" v-if="isAllowed">
      <button class="historiqueButton button" @click="accountManager.seeFiles(this)">Voir les fichiers</button>
      <table v-if="seeFile">
        <tr>
          <td class="h-blanc">Entreprise</td>
          <td class="h-noir">projet associé</td>
          <td class="h-autre">fichier</td>
          <td class="h-autre">Download</td>
        </tr>
        <FileR v-for="fichier in files" :key="fichier" :fichier="fichier" />
      </table>
    </div>
    -->
  </div>
</template>

<script>
import store from "../store"
//import axios from "axios";
//import FileR from "../components/file.vue"
import AccountModify from "../components/AccountModify.vue"
import file from "../components/folder.vue"
import uploadFile from "../components/uploadFile.vue"
const account = require("../Functions/AccountFunctions.js")

export default {
  name: "Profil",
  components: {
    //FileR,
    AccountModify,file,uploadFile
  },
  data() {
    return {
      seeFolder:"./",
      admin:false,
      isAllowed:false,
      accountManager :new account.default(this),
      nomActuel: "",
      files:[],
      seeFile:false,
      allowed:false,
      Loading:false,
      lastFolder:"",
      folder:["yopelait"],
      path:"",
      CurrentListOfFolder:[],
    };
  },
  methods: {
    change(f){
      this.accountManager.change(f,false)
    },
    getPath(){
      return this.path
    }
  },
  async mounted() {
    if(typeof store.state.user!="undefined"){
        await this.accountManager.update(this);
        await this.accountManager.validateLog(this);
        await this.accountManager.loadFolders();
        await this.accountManager.change("./",this)
    }
  },
  computed:{
    isRoot(){
            return this.accountManager.lastSelected!="./" 
        },
    },
  
     
};
</script>
<style scoped>
@import "../css/style.css";
</style>