<template>
<div class="row">
    <div class="colonne gaucheAbs" style="width: fit-content">
            <div class="texte gaucheAbs">

                <h1>Nous réalisons des amoires électriques pour  :
                </h1>

                <ul>
                    <li style="width: 293px">Gestion Technique Centralisée</li>
                    <li style="width: 293px">Gestion Technique du Batiment</li>
                    <li style="width: 293px">Machine de production, de contrôle</li>
                    <li style="width: 294px">Pilotage de process continu et discontinu</li>
                    <li style="width: 293px">Système de contrôle - Mesure</li>
                    <li style="width: 293px">Banc de test</li>
                    <li style="width: 293px">Supervision de process</li>
                </ul>
                  <h1  style="width: 210px; margin-top: 120px;">
                      <img id="ctl00_MainContent_Image1" class="logo" src="../assets/Images/Divers/EX.jpg" align="middle" style="border-style:Dotted;width:20px;border-width:0px;" />
                        &nbsp;&nbsp; 
                     Nous intervenons en atmosphere antidéflagrante, sur site classé SEVESO 2
                 </h1>
            </div>


            <div class="texte gaucheAbs" style="width: 323px; margin-top: 100px;">
                <h1>  
                   Nous câblons suivant les :
                </h1>

                <ul>
                    <li>Norme ATEX, Ex</li>
                    <li>Norme CEM</li>
                    <li>Norme agro-alimentaire</li>
                    <li>Connectique aéronautique</li>
                    <li>Norme Marine</li>
                    <li>Défense nationale</li>
                </ul>
            </div>

        </div>
        <div class="colonne" id="milieuA">
            <div class="banierre_type" style="margin-top:100px;">
                <img src="../assets/Images/Variateur/Variateur-x6reseau.jpg" alt="" style="height: 323px; width: 200px" />
                <p class="caption">Réseau de variateur </p>
                <p class="caption">Contrôle vitesse moteur</p>
            </div>
        </div>
        <div class="colonne droiteAbs bas" style="width: 250px">

            <div class="banierre_type gaucheAbs">
                <img src="../assets/Images/Armoire/Armoire-Distribution_puissance.jpg" alt="Distribution puissance" style="height: 253px; width: 187px" />
                <p class="caption">Distribution de puissance</p>
            </div>
            <div class="banierre_type gaucheAbs">
                <img src="../assets/Images/Armoire/Armoire_triple-Face_avant.jpg" alt="" style="height: 279px; width: 208px" />
                <p class="caption">Armoire puissance triple panneaux</p>
            </div>
             
        </div>
        

        

    </div>

</template>