<template>
  <tr>
    <td class="tdHistorique" id="blanc" :class="this.classBlanc">{{people.name}}</td>
    <td class="tdHistorique" id="noir" :class="this.classNoir">
      <input v-model="enterprise" v-on:keyup.enter="sendModif()" />
      <select v-model="enterpriseKnown">
    <option v-for="item in EnterpriseList" :value="item.name" :key="item">
        {{ item.name }}
    </option>
    <option>
      NOTKNOWN
      </option>
        </select>
      </td>
      <td class="tdHistorique" id="date">
      <img v-if="validated" src="../assets/Images/validation.png" style="width:50px;">
      <img v-else src="../assets/Images/none_validation.jpg" style="width:50px;"> 
      </td>
    <td class="tdHistorique" id="resultat" :class="this.classRes">
        <a class="Fbutton" @click="validate()" >Validate</a>
        <a class="Fbutton" @click="cancel()" >Cancel</a>
        </td>
  </tr>
</template>
<script>
import axios from "axios"
export default {
  name: "Profil",
  components: {},
  data() {
    return {
      classBlanc: "",
      classNoir: "",
      classRes: "",
      validated:this.people.validated,
      enterprise:this.people.enterprise,
      enterpriseKnown:"NOTKNOWN",
      
    };
  },
  props: {
    people: Object,
    EnterpriseList:Object,
  },
  methods: {
    async validate(){
      await axios
      .post("/user/validate",{username:this.people.name})
      .then((res) => {
        if(res.status=="200"){
          this.validated=true
        
        this.sendModif();
        this.$notify({
  title: "Validation de "+this.people.name,
  text: "validation bien prise en compte",
});}
      })
    },
    async cancel(){
      await axios
      .post("/user/unValidate",{username:this.people.name})
      .then((res) => {
        if(res.status=="200"){
          this.validated=false
        }
        this.$notify({
  title: "Validation de "+this.people.name,
  text: "cancel bien pris en compte",
});
      })
    },
    async sendModif(){
      if(this.enterprise!=this.people.enterprise){
        if(this.enterpriseKnown!="NOTKNOWN"){
          this.enterprise = this.enterpriseKnown
        }
      await axios
      .post("/user/ModifEnterprise",{username:this.people.name,enterprise:this.enterprise})
      .then((res)=>{
        console.log("plop"+res.data.message)
        this.$notify({
  title: "Validation de "+ this.people.name,
  text: "changement bien pris en compte",
});
      })
    }
  }}
}
</script>
