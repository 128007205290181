<template>
<div class="column" style="margin-left:200px;height:fit-content">
    <div>
    <br />

    <h1>Nous réalisons tout type de chariot, outil de manutention, levage, positionnement.
    <br />

        Exemples de chariot :
    </h1>
    <br />
    </div>
    <div class="row" style="justify-content:space-evenly">
        <div class="polaroid rotation_gauche7">
            <img src="../assets/Images/Outillage/Outillage-Chariot.jpg" alt="" style="height: 227px; width: 271px" />
            <p class="caption">Chariot pour réacteur.</p>
        </div>

        <!-- &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; -->

      <div class="polaroid rotation_droite8">
          <img src="../assets/Images/Outillage/Outillage-support_piece.jpg" alt="" style="height: 361px; width: 210px" />
          <p class="caption">Chariot de positionement pour pièce circulaire.</p>
      </div>


        <br />

        <div >
            <div class="polaroid rotation_gauche7">
                <img src="../assets/Images/Outillage/Outillage-Chariot_Inox.jpg" alt="" width="284" height="213" />
                <p class="caption">Chariot inox 316 pour produit chimique".</p>
            </div>

            <br />
        </div>
    </div>
    </div>

   

</template>