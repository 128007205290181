<template>
<div class="mini-grand">
    <div id="ctl00_MainContent_Image_de_fond" class="Image_accueil accueilFond2" 
     alt="Image text"  style="border-width:0px;margin-top:50px" >
    <!--/!--div-->

    

    <div id="text_accueil" style="margin-top:50px">

     
           <div id="Présentation générale">
            Le groupement Ducré comprend le bureau d’étude, un atelier de tôlerie et mécano-soudure, plusieurs ateliers de mécanique et un atelier d’électricité industrielle.
   L’entreprise réalise des études pour outillages et automatismes ainsi que des équipements de fabrication, des outillages de presse, du contrôle, des machines spéciales semi-automatiques et automatiques, des lignes de production et d’assemblage et encore d’autres prototypes divers.
        </div>

        <div id="Présentation client">


              <div class="photo_type droite carre">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2627.477933833188!2d2.435119215085431!3d48.81094181200135!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67326db3aa85b%3A0x2351e731504736f8!2s27%20Rue%20Gabriel%20P%C3%A9ri%2C%2094700%20Maisons-Alfort!5e0!3m2!1sen!2sfr!4v1668607356619!5m2!1sen!2sfr" width="300" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <p class="caption">Plan d'accès</p>
        </div>

            <h1>

                <br />
            Nos principaux clients
            </h1>
            <ul>

                <li>Thales</li>
                <li>Snecma</li>
                <li>Air France</li>
                <li>PARKER - Legris Autoline</li>
                <li>EADS - Aérospatiale</li>
                <li>Valéo</li>
                <li>Total</li>
                <li>Général Electric</li>
                <li>Schneider</li>
                <li>Messier Bugatti</li>
                <li>Famat</li>
                <li>Universcience</li>
                <li>...</li>
            </ul>
        </div>
        <div id="contact2" style="position:relative;margin-top:50px;margin-right:80px">
            DUCRE S.A.R.L.
        <br />
            27, Rue Gabriel Péri<br />
            94700 Maisons-Alfort<br />
            Tel : (33) 1.43.76.06.24<br />
            www.ducre.net<br />
            <a href="mailto:contact@ducre.net">contact@ducre.net</a><br />
        </div>
    </div>

     
</div>
</div>
</template>