<template>
<div>
  <a class="Fbutton" @click="select(folder)" >{{folder}}</a>
    </div>
</template>
<script>
export default {
  name: "Folder",
  components: {},
  data() {
    return {
    }
  },
  props: {
    folder: String,
  },
  methods:{
    select(f){
      this.$parent.change(f)
    }
  }
}
</script>
