<template>
<div>
  <link rel="icon" type="image/png" to="assets/Images/Logo/logo_d.jpg" /><link to="CSS/Style_SiteMaster.css" rel="stylesheet" type="text/css" /><link to="CSS/Style_Feuille.css" rel="stylesheet" /><link to="CSS/Menu.css" rel="stylesheet" />
            <div id="content">
              <notifications />
                <div id="header">
                    
                <div id="ligne">
                    <div class="block">
                    
                    <Header/>
                    <div id="identification">
                        <Login/>
                    </div>
                    </div>
                    </div>
                    <div id="navigation">
                        <Menu/>
                    </div>
                
                </div>
                    
                

                <div class="clear" />
            </div>
            
  <router-view/>
            <Footer/>
</div>
</template>
<script>
import Login from "./components/Login.vue"
import Footer from "./components/footer.vue"
import Menu from "./components/Menu.vue"
import Header from "./components/header.vue"
import store from "./store.js"

export default {
  name: "App",
  components: {Login,Footer,
  Menu,Header
  },
  data() {
    return {
    };
  },
  mounted(){
    store.state.notification=this.$notify//sauvegarde de la fonction de notification dans une variable
  },
  methods:{
  toMessage(title,msg){
    store.state.notification({text:msg,title:title})//utilisation de la fonction de notification préalablement
                                                    //sauvegardée
  }
  ,
  },
  watch:{

  },
  created() {
    /*window.addEventListener("beforeunload",
     (e) => {
      e.preventDefault()
      this.Disconnect()
     }
     );*/
  }
}
</script>

<style>
@import "css/Style_SiteMaster.css";
@import "css/Menu.css";
@import "css/Style_Feuille.css";
</style>
