<template>
<div>
    <link href="../Scripts/fancybox/jquery.fancybox.css" rel="stylesheet" />
    <div id="text_galerie">
        <div id="contact">
            DUCRE S.A.R.L.
        <br />
            27 Rue Gabriel Péri<br />
            94700 Maisons-Alfort<br />
            Tel : 01.43.76.06.24<br />
            www.ducre.net<br />
            <a href="mailto:contact@ducre.net">contact@ducre.net</a><br />
        </div>

        Parcourez notre galerie photo :
        <br />
    </div>

    <div id="galerie">
        <div @click="toImage()">
            <img  src="../assets/Images/Automatisme/Bouteille.jpg" style="width: 400px; height: 400px" alt="Embouteillage" />
        </div>
        <div v-if="show">
    <Gallerie/>
        </div>
        
    </div>
</div>
</template>
<script>
//import getAllImg from "../Functions/getImages"
import store from "../store"
import Gallerie from "./Galerie_Image.vue"
export default {
  name: "Galerie",
  data() {
    return {
      path:"/About/Galerie_Image",
      pathToImg:process.env.BASE_URL+"Gallerie/",
      show:false
    };
  },
  components:{
    Gallerie
  },
  methods: {
    async processToImg(liste){
        let result=[]
        for (let i=0;i<liste.length;i++){
            let resultString = liste[i]//.substring(9);
            result.push({"name":resultString})
        }
        console.log(result[0])
        return result
    },
    async toImage(){
        if(store.state.img==""){
        //let Images={};
        let assets = require.context("../../public/Gallerie",true,/^\.\/.*$/,'sync');
        //Images = await getAllImg(this.pathToImg)["obs2"];
        //store.state.img=Images
        //let imgs = {}
        const promise = this.processToImg(assets.keys()) 
        promise.then((res)=>{
            store.state.img=res
        })
        }
        //this.$
        this.show=true
        store.state.gallerie()
    }
  }
}
</script>
