<template>
    <div>
<div class="partie1">
        <div class="photo_type droite arrondi2">
            <img src="../assets/Images/IHM/IHM-Labuan.jpg" alt="" style="height: 270px; width: 351px" />
            <p class="caption">Ecran tactile pilotage centrale thermique</p>
        </div>

        <div class="texte gauche">

            <h1>Différents systèmes ont été développés :
            </h1>

            <ul>
                <li style="width: 293px">supervision de process automatisé</li>
                <li style="width: 293px">gestion de données et de recettes de fabrication</li>
                <li style="width: 293px">gestion de base de données fabrication</li>
                <li style="width: 293px">logiciel ISIC 4 de conception de recette</li>
                <li style="width: 293px">écran de contrôle-commande</li>
                <li style="width: 293px">centrale d'acquisition</li>

            </ul>
        </div>
      
    </div>
    <div class="partie2 row">
        <div class="photo_type gauche arrondi2">
            <img src="../assets/Images/IHM/IHM-Synoptique1.jpg" alt="" style="height: 226px; width: 277px" />
            <p class="caption">Synoptique suivi production</p>
        </div>

        <div class="texte droite" style="width:300px;">
            <h1>Nous développons des logiciels :
            </h1>
         
            <ul>
                <li style="width:300px;">en language C# - C++ - VB - Pascal - Assembleur</li>
                <li>avec les bases de données MySQL - SQL SERVER - ORACLE</li>
                <li>sur système Linux / Windows / OS2</li>
                <li>ou sur système embarqué</li>

            </ul>
        </div>


      
    </div>
    <div class="partie3">
        <div class="photo_type droite arrondi2">
            <img src="../assets/Images/IHM/IHM-Tactile.jpg" alt="Distribution puissance" style="height: 233px; width: 273px" />
            <p class="caption">Terminal tactile suivi process</p>
        </div>
          <div class="texte gauche">
            <h1>Nous réalisons :
            </h1>
            <ul>
                <li>des logiciels spécifiques sur demande</li>
                <li>des paramétrages et adaptations de logiciels existants</li>
                <li>des réseaux industriels</li>
            </ul>
        </div>
    </div>

    </div>
</template>