<template>
  <div>
    <lightbox id="mylightbox"
    ref="lightbox"
    :images="images"
    :directory="thumbnailDir"
    :timeoutDuration="5000"
></lightbox>
    <!-- Component name: 'vue-easy-lightbox' -->
    <!--
      <vue-easy-lightbox
        :visible="visible"
        :imgs="imgs"
        :index="index"
        @hide="handleHide"
      ></vue-easy-lightbox>
     -->
  </div>
</template>

<script>
import lightbox from 'vue-my-photos'
import store from '../store'
export default {
  components: {
    lightbox
  },
  data() {
    return {
      imagesTest:'',
      imgs: '../assets/Images/CDS/CDS-Pong.jpg',  // Img Url , string or Array
      visible: true,
      index: 0 ,  // default ../assets/le_frigo.jpg
      images : store.state.img,
      thumbnailDir:process.env.BASE_URL+"Gallerie/"
    }
  },
  async mounted() {
    store.state.gallerie = this.showLightbox
    this.showLightbox()
    /*let test = this.$refs.one.src.split(".")
    let test2 = test[0].split("/")
    this.images[0].name = test2[test2.length-1]+"."+test[1]+"."+test[2]*/
  }
  ,
  methods: {
    showLightbox: function() {
    this.$refs.lightbox.show(store.state.img[0].name);
    /*let test = this.$refs.one.src.split(".")
    let test2 = test[0].split("/")
    console.log(test2[test2.length-1]+"."+test[1]+"."+test[2])
    this.images[0].name = test2[test2.length-1]+"."+test[1]+"."+test[2]*/
},
    showSingle() {
      this.imgs = 'img/assets/Images/CDS/CDS-Pong.jpg'
      this.show()
    },
    showMultiple() {
      this.imgs = ['http://via.placeholder.com/350x150', 'http://via.placeholder.com/350x150']
      this.index = 1  // index of imgList
      this.show()
    },
    show() {
      this.visible = true
    },
    handleHide() {
      this.visible = false
    }
  }
}
</script>



