<template>
<body>
    <div class="collones plusbas">
    

    <div class="hero" align="middle" style="min-height:130vh">

   
        <div id="ctl00_MainContent_Image_de_fond" class="Image_accueil accueilFond" alt="Image text"  style="border-width:0px;" >


        <div id="text_accueil">
            &nbsp; &nbsp; &nbsp; &nbsp; 
                L’entreprise Ducré est une société d’études et de réalisations industrielles qui existe depuis 1951. 
         <br />
            <br />
            &nbsp; &nbsp; &nbsp; &nbsp; Son objectif est de concevoir, étudier, réaliser et assister les clients
            <br />
            dans leurs projets industriels ou scientifiques.
         <br />  <br />
            &nbsp; &nbsp; &nbsp; &nbsp; Le groupement Ducré comprend un bureau d’étude, un atelier de tôlerie et mécano-soudure, plusieurs ateliers de mécanique et un atelier d’électricité industrielle.
        
         <br />   <br />


            La société DUCRE innove et réalise pour ses clients :

                    <ul>
                        <li>des études électriques</li>
                        <li>des études mécaniques</li>
                        <li>des études d'automatisme</li>
                        <li>des outillages</li>
                        <li>des machines spéciales</li>
                        <li>des systèmes clé en main</li>

                    </ul>
            <br />
            <br />
                <a href="mailto:contact@ducre.net">N'hésitez pas à nous contacter pour tous renseignement</a><br />
                <div id="contact">
            DUCRE S.A.R.L.
        <br />
            27 Rue Gabriel Péri<br />
            94700 Maisons-Alfort<br />
            Tel : 01.43.76.06.24<br />
            www.ducre.net<br />
            <a href="mailto:contact@ducre.net">contact@ducre.net</a><br />
        </div>
        </div>
    
        </div>
    </div>
        
   
    </div>

</body>
</template>
<script>
//import Notif from "../components/Notif.vue"

export default {
  name: "Menu",
  components: {
    //Notif
    },
  data() {
    return {
      
      }},
      methods: {
      },
      mounted() {
        //window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js')
        
      }
}
</script>
