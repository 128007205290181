<template>
<div class="ligne" style="margin-bottom:50px;margin-top:50px;align-items:flex-start;">
    <table>
            <tr>
                <td class="h-noir">Nom Projet</td>
                <td class="h-autre">Entreprise</td>
                <td class="h-autre">Action</td>
            </tr>
            <tr>
            <td>
        <input type="text" v-model="nameProject"/>
        </td>
        <td>
        <select v-model="selected">
    <option v-for="item in items2" :value="item.name" :key="item">
        {{ item.name }}
    </option>
        </select>
        </td>
        <td>
        <button @click="addProject">Add Project</button>
        </td>
        </tr>
    </table>
</div>
</template>
<script>
import axios from "axios"
import store from '../store'
export default {
    data(){
        return {
            nameProject:"",
            selected: '',
            input: '',
            finalPath:"",
        }
    },
    props:{
        items2:Object,
    },
    methods: {
        async addProject(){
        await axios.put('/admin/project',{projectName:this.nameProject,enterpriseName:this.selected},{headers:{ 'x-access-token':store.state.token }})
        .then((res)=>{
            if(res.status=="200"){
                this.$notify({
                    text:"Ajouté",
                    title:"Projet "+res.data.message
                    })
            }
        })
    },
    }
}
</script>