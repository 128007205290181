<template>
<div class="ligne" style="margin-bottom:50px;margin-top:50px;align-items:flex-start;">
    <table>
            <tr>
                <td class="h-blanc">File</td>
                <td class="h-noir">Dossier supplémentaire</td>
                <td class="h-autre">Dossier répertoire</td>
                <td class="h-autre">Action</td>
            </tr>
            <tr>
            <td>
            <input type="file" @change="uploadFile" ref="file" charset='UTF-8'>
            </td>
        <td>
            <input type="text" v-model="path">
        </td>
        <td>

        <select v-model="selected">
    <option v-for="item in items2" :value="item" :key="item">
        {{ item }}
    </option>
        </select>
        </td>
        <td>
        <button @click="submitFile">Upload!</button>
        </td>
        </tr>
            </table>
        
</div>
</template>
<script>
import axios from "axios"
import store from '../store'
export default {
    data(){
        return {
            path:"",
            tmpPath:"",
            selected: './',
            input: '',
            finalPath:""
        }
    },
    props:{
        items2:Object,
        admin:Boolean,
        last:String,
    },
    methods: {
      uploadFile() {
        this.Images = this.$refs.file.files[0];
      },
      async submitFile() {
        const formData = new FormData();
        formData.append('file', this.Images);
        if(!this.path==""){
            this.tmpPath="/"+this.path
        }else {
            this.tmpPath=this.path
        }
        
        if(this.selected!="./" && this.selected!=""){
            this.selected=this.selected.slice(1)
        }
        if(this.admin){
            this.finalPath=this.$parent.getPath()+"/"+this.selected+this.tmpPath+"/"
        }
        else if(!this.admin){
            if(this.tmpPath=="./"){this.tmpPath="."}
            this.finalPath="/"+store.state.user.enterprise+this.$parent.getPath()+"/"+this.selected+this.tmpPath+"/"
            console.log(store.state.user.enterprise)
            console.log("not admin")
        }
        //const headers = { 'Content-Type': 'multipart/form-data' };
        await axios.post('/files/uploadName',{name:this.Images.name,path:this.finalPath},{headers:{ 'x-access-token':store.state.token }}).then((res)=>{
            if(res.status=="200"){
                console.log("firt part upload succeded")
                console.log(this.finalPath)
            }
        })
        await axios.post('/files/upload/'+this.$parent.admin,formData,{headers:{ 'x-access-token':store.state.token }}).then((res) => {
          res.data.files; // binary representation of the file
          res.status; // HTTP status
        });
        this.finalPath=""
        this.path=""
        this.tmpPath=""
        }
        
      }
}
</script>