<template>
    <div class="row" style="margin-top:50px;">
    <div class="column" style="align-items:center;">
        <div class="photo_type arrondi" style="width:200px;">
            <img src="../assets/Images/Machine/Banc-Baie.jpg" id="ImgCentered" alt="" style="height: 259px; width: 150px" />
            <p class="caption" >Banc avec sa baie</p>
        </div>

        <div class="texte" style="margin-top: 20px; height: 210px; width: 272px;">
          
            <h1>Nous réalisons des bancs d'essai et <br />
                des bancs de test sur mesure :
            </h1>
            <ul>
                <li>Prototype</li>
                <li style="width: 293px">Banc d'essai</li>
                <li style="width: 293px">Banc de fatigue</li>
                <li style="width: 293px">Banc d'endurance</li>
                <li style="width: 293px">Banc de mesure</li>
                <li style="width: 293px">Banc de tir</li>

            </ul>

        </div>
    </div>
    <div class="column" style="align-items:center;">
        <div class="photo_type arrondi" style="width:200px;">
            <img src="../assets/Images/Baie/Baie-Controle1.jpg" id="ImgCentered" alt="Baie de contrôle" style="height: 252px; width: 100px" />
            <p class="caption">Baie de contrôle</p>
        </div>

        <div class="texte" style="margin-top: 28px; width: 275px;">
            <h1>Nos bancs d'essai incorporent :
            </h1>
            <br />
            <ul>
                <li>des centrales d'acquisitions</li>
                <li>des automatismes</li>
                <li>capteurs et détecteurs</li>
            </ul>
        </div>


        <br />
    </div>
    <div class="column" style="align-items:center;">
        <div class="photo_type arrondi" style="width:200px;">
            <img src="../assets/Images/Machine/Machine-Etancheite.jpg" alt="Banc de test" id="ImgCentered" style="height: 252px;width:200px" />
            <p class="caption">Banc de test pièce</p>
        </div>

        <div class="texte" style="width: 275px;margin-top:25px;">

            <h1>
                Les bancs sont adaptés à votre demande.
                  <br />
                Ils utilisent différentes énergies :
            </h1>

            <ul>
                <li>électrique</li>
                <li>pneumatique</li>
                <li>hydraulique</li>
            </ul>

            <br />



        </div>
    </div>
    </div>
</template>