<template>
<div>
    <fieldset>
<legend>{{Title}}</legend>
<radio-buttons v-for="radio in RadioButtons" :key="radio.value" :RadioButton="radio" :name="Title" :f="f"  />
    </fieldset>
</div>
</template>
<script>
import radioButtons from "./radioButtons.vue"
export default {
    components:{
        radioButtons
    }
    ,
    props:{
        RadioButtons:Array,
        Title:String,
        f:Function
    }
}
</script>