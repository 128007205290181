<template>
  <div id="Content">
    <div class="flexbox column interfaceLog">
      <h1 class="titre">Connexion:</h1>
      <input
        class="logInput"
        type="text"
        id="utilisateur"
        name="utilisateur"
        placeholder="Nom d'utilisateur"
        v-model="user.username"
      />
      <input
        class="logInput"
        type="password"
        id="motpasse"
        name="motpasse"
        placeholder="Mot de passe"
        v-model="user.password"
      />
      <div id="buttons" class="flexbox row logButtons">
        <button v-on:click="onConnexion()" class="button">Connexion</button>
        <button v-on:click="onForgot()" class="button">Forgot Password</button>
        <!-- <RouterButton :path="path" :name="name"/> -->
      </div>
    </div>
  </div>
</template>

<script>
//import RouterButton from "../components/router-Button.vue"
import axios from "axios";
import store from "../store";

export default {
  name: "Connexion",
  components: {
    //RouterButton
    },
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      name:"Inscription",
      path:"/User/Sign",
    };
  },
  props: {
    msg: String,
  },
  methods: {
    async onForgot(){
      this.$router.push("/mail")
    },
    async onConnexion() {
      await axios
        .post("/user/login", this.user)
        .then((res) => {
          
          if (res.data.message == "Valid password" && res.status=="200") {
            this.$notify({
  title: "Bonjour "+this.user.username,
  text: "Comment allez vous ?",
})          
            store.state.user = {
              enterprise:res.data.enterprise,
              name:res.data.name
            }
            store.state.co=true
            store.state.token=res.data.token
            this.$router.push('/')
          }
        });
      window.scrollTo(0, 0);
    },
  },
};
</script>