<template>
<div class="row">
    <div class="column" style="height:300px">
        <div class="photo_type droite ovale" style="
        width: 252px;
    position: relative;
    height: 249px;
    top: 0px;">

            <img src="../assets/Images/Outillage/Outillage-Levage.jpg" alt="Levage" style="
            height: 198px;
    width: 260px;
    display: block;
    margin-right: auto;
    margin-left: auto;" 
            />

            <p class="titre_photo" style="width: 203px">Système de levage</p>
        </div>

        <div class="texte gauche" style="width: 407px; height: 190px; left: 1px; top: 10px;">

            <h1>Nous réalisons égakelent des outillages spéciaux sur demande :
            </h1>
            <ul>

                <li>Système de levage</li>
                <li>Système de maintien</li>
                <li>Système de positionnement</li>
            </ul>


        </div>
    </div>
    <div class="column" style="width:428px;">
        <div class="photo_type gauche ovale" style="
        width: 252px;
    position: relative;
    height: 249px;
    top: 0px;
        ">
            <img src="../assets/Images/CAO/Etude-Controle.jpg" alt="Robot de manipulation" style="height: 198px; width: 260px" />
            <p class="caption">Robot de manipulation</p>
        </div>

        <div class="texte droite" style="width: 400px; height: 200px;position:relative;right:0%">
            <h1>Chaque réalisation est spécifique et répond à une demande client.
         <br />
                Nous intervenons dans les domaines de :
            </h1>

            <ul>
                <li>Assemblage de pièces</li>
                <li>Tri de pièces</li>
                <li>contrôle qualité</li>
                <li>Positionnement à haute vitesse</li>
            </ul>
        </div>
    </div>

    <br />
    <div class="column">
        <div class="photo_type droite ovale" style="position:relative;top:0px;">
            <img src="../assets/Images/CAO/Etude-Support_piece.jpg" alt="Support de pièce" style="height: 191px; width: 242px;" />
            <p class="caption">Support de pièce</p>
        </div>

        <div class="texte gauche" style="width: 384px; height: 145px;">


            <h1>Les machines peuvent être automatiques, semi-automatiques ou manuelles.
                 <br />
                <br />
                Les machines peuvent être linéaires ou rotatives.  
            </h1>
        </div>
    </div>
</div>
</template>