<template>
<div class="row">
    <div class="column gauche" style="height: 866px;">
        <div class="photo_type droite  arrondi Milieu" style="width: fit-content;">
            <img src="../assets/Images/CAO/Schema-VUE_ARMOIRE.jpg" alt="Vue armoire" style="height: 297px; width: 210px;
            display: block;
    margin-left: auto;
    margin-right: auto;" />
            <p class="caption">Vue intérieure armoire</p>
        </div>
    
        <div class="texte g" style="width: 300px; height: 305px;">
            <h1>Les armoires sont réalisées suivant votre cahier des charges.
                  <br />
                Nous vous aidons à rédiger des analyses fonctionnelles détaillées.
                  <br />
                L'ensemble des schémas est réalisé sous DAO et inclut :
                   <ul>
                       <li>Capteurs d'effort</li>
                       <li>Capteur de fatigue</li>
                       <li>Capteur dimentionnel (au micron)</li>
                       <li>Capteur de présence</li>
                        <li>Capteur de luminosité</li>
                       <li>Sonomètre</li>
                       <li>Capteur d'explosion</li>
                   </ul>
            </h1>
        </div>
    </div>
    <div class="column droite" style="height: 866px;">
        <div class="photo_type gauche arrondi Milieu" style="width: fit-content;">
            <img src="../assets/Images/CAO/Schema-SORTIE_AUTOMATE.jpg" alt="Carte Automate" style="height: 297px;
             width: 210px;
             display: block;
    margin-left: auto;
    margin-right: auto;" />
            <p class="caption">Carte automate</p>
        </div>

        <div class="texte g">

            <h1>Nous pouvons effectuer des mesures :
            </h1>

            <ul>

                <li>dimentionnelles par capteur</li>
                <li>dimentionnelles par capteur</li>
                <li>Contrôle de résistance</li>
                <li>Contrôle d'isolement</li>
                <li>Contrôle de varistance</li>
            </ul>

        </div>
    </div>
    <div class="column droite">
        <div class="photo_type gauche arrondi Milieu" style="width: fit-content;
        ">
            <img src="../assets/Images/CAO/Schema-Bornier.jpg" alt="Borniers" style="height: 297px; width: 210px;
            display: block;
    margin-left: auto;
    margin-right: auto;" />
            <p class="caption">Borniers de liaison</p>
        </div>

        <div class="texte g" style="width:300px">
            <h1>Nous intégrons des centrales de mesures avec des baies 
            </h1>
          
            <ul>
                <li>Reconnaisssance de forme par caméra</li>
                <li>Reconnaissance de couleur</li>
            </ul>
        </div>


        <br />
    </div>
    </div>
</template>