<template>
    
                <div class="block">
                    <img id="ctl00_Image_logo" class="logo" src="../assets/logo_ducre.gif" align="middle" style="border-style:Dotted;width:173px;border-width:0px;" />
                 <!--   <span class="title"><span style="color: blue">S</span>ociété d'<span style="color: blue">E</span>tudes et de <span style="color: blue">R</span>éalisations <span style="color: blue">I</span>ndustrielles</span>-->
                    <span class="title"><span style="color: blue">E</span>tudes et <span style="color: blue">R</span>éalisations</span>
                  <br/>
                    <div id="depuis1951" align="left">
                        depuis 1951 
                    </div>
                </div>
</template>